<template>
    <header class="header" v-if="isUserLoaded && !needAuth && !isBanned && !isNoSuccess && !isNoSuccessSection && !isFillSpecific"  :class="{'--no-bottom-header': (!isShowBottomHeader || (!isDevPeriodPageSecondMenu && isDevPeriodPage)) || isGiveDesign}">
        <div class="header__top" :class="{'is-director': user.groupCode == 'V' || user.groupCode == 'D'}">
            <div class="inner-wrapper">
                <div class="h-left" :class="{'is-director': user.groupCode == 'V' || user.groupCode == 'D'}">
					<template v-if="!isStaffPage">
						<router-link class="h-logo" to="/">
	                        <img src="@/assets/img/zems-logo.svg" alt="">
	                    </router-link>
						<top-menu-buh v-if="user.groupCode == 'F'" />
                        <top-menu v-else :class="{'hide': (user.groupCode == 'M' || user.groupCode == 'B')}"/>
                        <sub-menu :template="'master'" v-if="user.groupCode == 'M'"/>
                        <sub-menu :template="'master'" v-if="user.groupCode == 'B'"/>
					</template>
					<template v-else>
						<router-link class="h-logo" to="/staff/">
	                        <img src="@/assets/img/zems-logo.svg" alt="">
	                    </router-link>
                          <top-menu/>
					</template>

                </div>
                <div class="h-right">
                    <a href="javascript:void(0);" title="Передача проекта" @click="openGiveProject" v-if="user.groupCode == 'D'" class="stats-link --move">
                        <svg class="move-icon">
                            <use xlink:href="@/assets/img/move.svg#move"></use>
                        </svg>
                    </a>
                    <a href="javascript:void(0);" class="link-yellow text-bold nowrap"  v-if="user.salary" @click="openUserSalary()">{{number_format(user.salary)}} <span class="rouble">{{ $store.getters['options/setRouble'] }}</span></a>
                    <div class="" v-if="!isStaffPage">
                         <search-top />
                    </div>
					<router-link title="Сроки разработки" v-if="user.groupCode == 'D' || user.groupCode == 'V' || user.groupCode == 'R' || user.groupCode == 'K' || user.groupCode == 'TD' || user.groupCode == 'T' || user.groupCode == 'P'|| user.groupCode == 'AR'" :class="{'router-link-active':isDevPeriodPage}" :to="isDevPeriodPage?'/requests/':'/dev-period/'" class="staff-link">
                        <span class="i-staff-wrapper">
                            <span class="i i-clock2"></span>
                        </span>
                    </router-link>
					<router-link v-if="user.groupCode == 'D' || user.groupCode == 'V'" :class="{'router-link-active':isStaffPage}" :to="isStaffPage?'/requests/':'/staff/'" class="staff-link">
                        <span class="i-staff-wrapper">
                            <span class="i i-staff"></span>
                        </span>
                    </router-link>
                    <router-link class="stats-link" :to="'/statistic'" :class="{'active': $route.path == '/statistic'}" v-if="false">
                        <svg class="money-icon">
                            <use xlink:href="@/assets/img/money.svg#money"></use>
                        </svg>
                    </router-link>
                    <router-link class="stats-link" :to="'/nps-collect/nps-collect-new'"
                                 :class="{'active':isNPSCollect}"
                                 v-if="user.groupCode == 'D' || user.groupCode == 'V' || user.groupCode == 'N'">
                        <svg class="quest-icon">
                            <use xlink:href="@/assets/img/quest.svg#quest"></use>
                        </svg>
                    </router-link>
                    <router-link class="stats-link" :to="'/nps-measure/'"
                                 :class="{'active':isNPSMeasure}"
                                 v-if="user.groupCode == 'D' || user.groupCode == 'V' || user.groupCode == 'N' || user.groupCode == 'A' || user.groupCode == 'TD'|| user.groupCode == 'R'">
                        <svg class="smile-icon">
                            <use xlink:href="@/assets/img/smile.svg#smile"></use>
                        </svg>
                    </router-link>
                    <router-link class="stats-link" :to="'/workload'" v-if="user.groupCode == 'D' || user.groupCode == 'V'">
                        <svg class="money-icon">
                            <use xlink:href="@/assets/img/workload.svg#workload"></use>
                        </svg>
                    </router-link>

                    <router-link v-if="user.groupCode == 'D' || user.groupCode == 'V' || user.groupCode == 'A' || user.id == '3429' || user.groupCode == 'TD' || user.groupCode == 'F'" :to="'/statistic'" class="stats-link" :class="{'active': $route.path == '/statistic'}">
                        <svg class="stats-icon">
                            <use xlink:href="@/assets/img/stats.svg#stats"></use>
                        </svg>
                    </router-link>
					<router-link class="stats-link" :to="'/buh'" :class="{'active': $route.name === 'buh' || $route.name === 'buhMaster'}" v-if="user.groupCode == 'V'">
                        <svg class="money-icon">
                            <use xlink:href="@/assets/img/buh.svg#buh"></use>
                        </svg>
                    </router-link>
                    <user-tooltip></user-tooltip>
                </div>
            </div>
        </div>
        <div class="header__bottom" v-if="($route.name === 'buh' || $route.name === 'buhMaster') && user.groupCode == 'V'">
            <div class="inner-wrapper">
                <sub-menu-buh />
            </div>
        </div>
        <template v-else-if="isDevPeriodPage">
            <div class="header__bottom" v-if="isDevPeriodPageSecondMenu"  :class="{'is-director': user.groupCode == 'V' || user.groupCode == 'D'}">
                <div class="inner-wrapper">
                    <sub-menu-dev-period />
                </div>
            </div>
        </template>
        <template v-else-if="isNPSCollect || isNPSMeasure">
            <div class="header__bottom">
                <div class="inner-wrapper">
                    <div class="df df-space-between">
                        <sub-menu-nps v-if="isNPSCollect"/>
                    <div class="plug" v-else></div>
                    <search-nps></search-nps>
                    </div>
                </div>
            </div>
        </template>
        <div class="header__bottom" v-else-if="$route.name !== 'workload' && $route.name !== 'search' && $route.name !== 'statistic' && !isNPSMeasure && user.groupCode !== 'F' && user.groupCode !== 'M' && user.groupCode !== 'B' && !isGiveDesign" :class="{'is-director': user.groupCode == 'V' || user.groupCode == 'D'}">
            <div class="inner-wrapper">
                <sub-menu/>
            </div>
        </div>
    </header>
    <!-- ЛОГ -->
    <log :show="popups.log.show" />

    <popup :show="popups.errorModal.show"
           :width="540"
           :name="popups.errorModal.name">
        <error-modal/>
    </popup>
    <popup :show="popups.errorCustomTextModal.show"
           :width="540"
           :name="popups.errorCustomTextModal.name">
        <error-custom-text-modal/>
    </popup>

	<popup :show="popups.changeRole.show"
           :width="540"
           :name="popups.changeRole.name">
        <change-role/>
    </popup>
    <popup :show="popups.userSalary.show"
           :width="540"
           :name="popups.userSalary.name">
        <user-salary/>
    </popup>

    <router-view v-if="!needAuth && !isBanned && !isNoSuccess && !isNoSuccessSection && isUserLoaded"/>

    <section v-else-if="isUserLoaded" class="page-auth__section">
        <div class="content content__page-auth">
            <div class="text-center zems-logo-auth" >
                <img src="@/assets/img/zems-logo.svg" alt="">
            </div>

            <section class="container mt40">
                <div class="auth-form-container">
                    <auth v-if="needAuth && !isBanned && !isNoSuccess && !isNoSuccessSection"></auth>
                    <div v-else-if="isBanned" class="auth-form">
                        <div class="auth-form__error">
                            <div class="text-center">
                                <svg class="big-icon" >
                                    <use xlink:href="@/assets/img/big-icons-sprite.svg#many-auth"></use>
                                </svg>
                            </div>

                            <div class="mt32 text-center">
                                <div class="text-size-l text-bold text-color-red">Слишком много попыток входа!</div>
                                <div class="mt16 text-size-ms">
                                    Вы заблокированы на 12 часов. Если у вас что-то срочное,<br>
                                    напишите администратору на <a href="mailto:zems@zems.pro">zems@zems.pro</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="isNoSuccess" class="auth-form">
                        <div class="auth-form__error">
                            <div class="text-center">
                                <div>
                                    <img src="@/assets/img/cat.svg" alt="">
                                </div>

                                <div class="text-size-l text-bold text-color-red mt32">
                                    У номера {{ user.phone ? user.phone : formAuthFields.login.value }}<br class="o-mob"> нет доступа к сайту
                                </div>
                                <div class="mt16 text-size-ms">
                                    Если он должен быть,<br>
                                    напишите администратору на <a href="mailto:zems@zems.pro">zems@zems.pro</a>
                                </div>
                                <div class="mt32 text-center auth-btn">
                                    <button @click="clearToken();" class="btn" style="display: inline-block;"> перейти на главную </button>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div v-else-if="isNoSuccessSection" class="auth-form" >
                        <div class="auth-form__error">
                            <div class="text-center">
                                <div>
                                    <svg class="big-icon" >
                                        <use xlink:href="@/assets/img/big-icons-sprite.svg#cat"></use>
                                    </svg>
                                </div>

                                <div class="text-size-l text-bold text-color-red mt32">
                                    У номера {{ user.phone }} нет доступа<br>
                                    к этому разделу
                                </div>
                                <div class="mt16 text-size-ms">
                                    Если он должен быть,<br>
                                    напишите администратору на <a href="mailto:zems@zems.pro">zems@zems.pro</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="footer">
            <span>© 1980-2092 Алексей Земсков</span>
        </div>
    </section>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue';
import TopMenuBuh from '@/components/TopMenuBuh.vue';
import SubMenu from '@/components/SubMenu.vue';
import SubMenuBuh from '@/components/SubMenuBuh.vue';
import SubMenuDevPeriod from '@/components/SubMenuDevPeriod.vue';
import SubMenuNps from '@/components/SubMenuNps.vue';
import Tooltip from '@/components/Tooltip.vue';
import UserTooltip from '@/components/UserTooltip.vue';
import Auth from '@/components/AuthPersonal.vue';
import VueFooter from '@/components/VueFooter.vue';
import {mapState} from 'vuex';
import {mapGetters} from 'vuex';
import Popup from '@/components/modal/Popup.vue';
import ErrorModal from '@/components/modal/ErrorModal.vue';
import ErrorCustomTextModal from '@/components/modal/ErrorCustomTextModal.vue';
import Log from '@/components/modal/Log.vue';
import UserSalary from '@/components/modal/userSalary.vue';
import SearchTop from '@/components/SearchTop.vue';
import ChangeRole from '@/components/modal/ChangeRole.vue';
import {number_format} from '@/helpers/formatted.js';
import SearchNps from '@/components/SearchNps.vue';

export default {
    name: "MainSection",
    components: {
        TopMenu,
        TopMenuBuh,
        SubMenu,
        SubMenuBuh,
        SubMenuDevPeriod,
        SubMenuNps,
        Tooltip,
        Auth,
        VueFooter,
        Log,
        Popup,
        UserTooltip,
        SearchTop,
        ErrorModal,
        ErrorCustomTextModal,
		ChangeRole,
        UserSalary,
        SearchNps,
    },
    created() {
        this.$store.dispatch('options/getList');
    },
    methods: {
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
        openUserSalary(){
            this.$store.dispatch('popups/open', {name: this.popups.userSalary.name});
        },
        clearToken() {
            window.localStorage.removeItem('vuex');
            window.location.href = process.env.VUE_APP_PATH_FRONT;
        },
        openGiveProject() {
            this.$store.dispatch('popups/open', { name: 'giveProject' });
        }
    },
    computed: {
        ...mapState({
            isUserLoaded: state => state.auth.isLoaded,
            user: state => state.auth.user,
            formAuthFields: state => state.auth.forms.auth.fields,
            isBanned: state => state.auth.isBanned,
            isNoSuccess: state => state.auth.isNoSuccess,
            isNoSuccessSection: state => state.auth.isNoSuccessSection,
            popups: state => state.popups.items,
            localStorage: state => state.auth.localStorage,
        }),
        ...mapGetters({
                needAuth:'auth/needAuth',
            }
        ),
		isShowBottomHeader(){
			return (this.$route.name !== 'search' && this.$route.name !== 'statistic' && this.user.groupCode !== 'F' && this.user.groupCode !== 'M') || ((this.$route.name === 'buh' || this.$route.name === 'buhMaster') && this.user.groupCode == 'V');
		},
		isStaffPage() {
			return this.$route.name === 'staff' || this.$route.name === 'staffDetail';
		},
		isNPSCollect() {
			return this.$route.name === 'nps-collect' || this.$route.name === 'nps-collect-current';
		},
		isNPSMeasure() {
			return this.$route.name === 'nps-measure' || this.$route.name === 'nps-measure-current';
		},
		isDevPeriodPage() {
			return this.$route.name === 'dev-period' || this.$route.name === 'dev-period-current';
		},
        isDevPeriodPageSecondMenu(){
            return (this.user.groupCode == 'T' || this.user.groupCode == 'P')?false:true;
        },
        isFillSpecific() {
            return this.$route.name === 'fill-specific' || this.$route.name === 'check-specific' || this.$route.name === 'fill-specific-other' || this.$route.name === 'check-specific-other';
        },
        isGiveDesign() {
            return this.$route.name === 'give-design';
        },
    },
    mounted () {
        if(!(this.isNPSCollect || this.isNPSMeasure) && this.user.groupCode == 'N') {
            this.$router.push('/nps-collect/nps-collect-new');
        }
    },
}
</script>

<style scoped>

</style>
